/* eslint-disable no-relative-import-paths/no-relative-import-paths */
import '@/bootstrap';
import formKitTheme from '@/formkitTheme';
import {createAutoAnimatePlugin} from '@formkit/addons';
import {nl} from '@formkit/i18n';
import {generateClasses} from '@formkit/themes';
import {defaultConfig as FKdc, plugin as FKp} from '@formkit/vue';
import {createGtm} from '@gtm-support/vue-gtm';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import Notifications from 'notiwind';
import {createPinia} from 'pinia';
import {createSSRApp, h} from 'vue';
import {createI18n} from 'vue-i18n';
import dayjs from './plugins/dayjs';
import {ZiggyVue} from './plugins/ziggy';
import localeMessages from './vue-i18n-locales.generated';
import {Ziggy} from './ziggy';
import {flareVue} from "@flareapp/vue";
import flare from './plugins/flare';

if (import.meta.env.PROD) {
    flare.light();
}

const pinia = createPinia();

const numberFormats = {
    nl: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        percent: {
            style: 'percent',
            useGrouping: true,
        },
    },
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent',
            useGrouping: false,
        },
    },
};
createInertiaApp({
    progress: {color: '#fff', delay: 500},
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue')
        ),
    setup({el, App, props, plugin}) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            numberFormats,
            legacy: false,
            locale: currentLocale,
            fallbackLocale: 'en',
            messages: localeMessages,
        });

        return createSSRApp({render: () => h(App, props)})
            .use(plugin)
            .use(pinia)
            .use(flareVue)
            .use(Notifications)
            .use(ZiggyVue, {...Ziggy, locale: currentLocale})
            .use(dayjs, {locale: currentLocale})
            .use(i18n)
            .use(
                createGtm({
                    id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
                    enabled:
                        import.meta.env.VITE_GOOGLE_TAG_MANAGER_ENABLED ===
                        'true' && import.meta.env.PROD,
                })
            )
            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    plugins: [createAutoAnimatePlugin({duration: 150})],
                    locales: {nl},
                    locale: 'nl',
                    globalInjection: true,
                })
            )
            .mount(el);
    },
});
